require('Modernizr');

import Form from '@searchtrends/webform-processor';

const axios = require('axios/dist/browser/axios.cjs'); // browser commonJS bundle (ES2017)
window.axios = axios;

try {
    Modernizr.on('webp', function (result) {
        if (result) {
            document.querySelector('body').classList.add('webp');
        } else {
            document.querySelector('body').classList.add('no-webp');
        }
    });
} catch (e) {
    document.querySelector('body').classList.add('no-webp');
}

window.hideGoogleMaps = function () {
    document.cookie = "showGoogleMaps=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict";

    location.reload();
}

window.allowGoogleMaps = function () {
    document.cookie = "showGoogleMaps=" + Math.floor(Date.now() / 1000) + "; path=/; SameSite=Strict";
}

window.loadMapsJs = function() {
    if (document.querySelector('.listing-maps')) {
        document.querySelector('.listing-maps').style.height = '215px';
    }
    if (document.querySelector('.listing-maps #map')) {
        document.querySelector('.listing-maps #map').style.height = '200px';
    }

    let token = document.head.querySelector('meta[name="maps-token"]');
    let script = document.createElement('script');

    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + token.content;

    script.async = true;
    script.defer = true;

    document.head.appendChild(script);
    script.onload = () => {
        initMap();
    }
}

let contactforms = {};
let forms = document.querySelectorAll('form[method="post"]:not(.no-ajax)');
forms.forEach(function (cf, index) {
    contactforms[`sendform_${index}`] = new Form(cf, index).onlyOnce();

    cf.addEventListener('submit', function (e) {
        e.preventDefault();

        contactforms[`sendform_${index}`].post(cf.action)
            .then(response => { console.log(response); });
    });
});

document.querySelectorAll('.dropdown').forEach(function (link) {
    link.addEventListener('click', function (e) {
        e.preventDefault();

        if (document.querySelector(`.dropdown-${this.dataset.dropdownMenu}`)) {
            let div = document.querySelector(`.dropdown-${this.dataset.dropdownMenu}`);
            div.classList.toggle('hidden');
        }
    })
});

if (document.querySelector('.menu')) {
    document.querySelector('.menu').addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector('.nav-items').classList.toggle('hidden');
    })
}
